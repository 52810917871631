<template>
  <div class="houseInfo-warp container flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img
        class="img-title"
        :src="require('@/assets/image/house.png')"
        alt=""
      />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/city.png')"
            class="img-icon"
          /><span>房屋详情</span>
        </div>
      </div>
    </div>
    <div class="panel-warp flexone">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">房主姓名</div>
          <div class="panel-item-right bra3 panel-item-block plr">{{forms.hh}}</div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">房主身份证</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{forms.cid}}
          </div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block">房主电话</div>
          <div class="panel-item-right bra3 panel-item-block plr">
             {{forms.phone}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">一级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaOne}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">二级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaTwo}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">三级网格</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.areaThree}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">门牌号</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.hn}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">户型</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.ht}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            详细地址
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{forms.addr}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">房屋属性</div>
          <div class="panel-item-right bra3 plr panel-item-block">
             {{ $dt.changeCodeName('house_attr', forms.attr )}}
          </div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">房屋用途</div>
          <div class="panel-item-right bra3 plr panel-item-block">xxxxxx</div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block">房屋层数</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.floor}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">房屋面积</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.area}}</div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">房屋状态</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{forms.is_db ? "危房":"正常"}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">产权类型</div>
          <div class="panel-item-right bra3 plr panel-item-block">
             {{ $dt.changeCodeName('house_prt', forms.prt )}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">产权证号</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.prn}}</div>
        </li>
        <!-- <li>
          <div class="panel-item-left bra3 panel-item-block">房屋层数</div>
          <div class="panel-item-right bra3 plr panel-item-block">{{forms.floor}}</div>
        </li> -->
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            备注信息
          </div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{forms.remark}}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">照片</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            <img
               v-for="(item,index) in forms.photos"
              :key="index"
              :src="item"
              class="panel-img-square"
              alt=""
                     @click="handlePreImg(forms.photos,index)"
            />
          </div>
        </li>
      </ul>
      <van-image-preview v-model="show" closeable :images="preImages" :start-position="startIndex"> 
      </van-image-preview>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/house/get",
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less">
.houseInfo-warp {
  letter-spacing: 0px;
  position: relative;
}
</style>
