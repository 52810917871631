import httpService from "@/plugins/http";
export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_API_URL,
            forms: {},
            show: false,
            startIndex: 0,
            preImages: [],
            videourl: "",
            showvideoplay: false
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        async getInfo() {
            try {
                this.$store.commit("showLoading");
                const { id } = this.$route.query;
                const { code, data } = await httpService.post(this.interfaceUrl, {
                    id: Number(id),
                    start_page: 1,
                    size_per_page: 10,
                });
                await this.getTree();
                if (code === 0) {
                    this.forms = Array.isArray(data) && !!data.length ? data[0] : {};
                    // 照片
                    this.forms.photos = this.formatData(this.forms.photo || this.forms.pic)
                    // 视频
                    this.forms.videos = this.formatData(this.forms.video)
                    // 处理照片
                    this.forms.hdlPhotos = this.formatData(this.forms.hdl_pic || "")
                    // 处理视频
                    this.forms.hdlVideos = this.formatData(this.forms.hdl_video || "")

                    let parentNodes = this.getParentNodes(this.allTreeList, this.forms.area_id)
                    this.forms.areaOne = parentNodes[0] ? parentNodes[0].name : "";
                    this.forms.areaTwo = parentNodes[1] ? parentNodes[1].name : "";
                    this.forms.areaThree = parentNodes[2] ? parentNodes[2].name : "";
                    this.forms.areaAllName = parentNodes.map(item => item.name).join('/')
                } else {
                    this.$store.commit("hideLoading");
                }
            } catch (error) {
                console.log(error, "error");
            } finally {
                this.$store.commit("hideLoading");
            }
        },
        formatData(data) {
            let result = data ? data.split(",") : []
            result = result.map(item => {
                return this.baseUrl + item
            })
            return result
        },
        async getTree() {
            try {
                const res = await httpService.post("/api/area/tree", {});
                if (res.code === 0) {
                    this.allTreeList = res.data || []
                }
            } catch (error) {
                console.log(error, "error");
            }
        },
        getParentNodes(arr1, id) {
            var temp = []
            var forFn = function (arr, id) {
                for (var i = 0; i < arr.length; i++) {
                    var item = arr[i]
                    if (item.id === id) {
                        forFn(arr1, item.parent)
                        temp.push(item)
                        break;
                    } else {
                        if (Array.isArray(item.node) && item.node.length) {
                            forFn(item.node, id)
                        }
                    }
                }
            }
            forFn(arr1, id)
            return temp
        },
        handlePreImg(arr, index) {
            this.preImages = [...arr]
            this.startIndex = index
            this.show = true
        },
        handlePreVideo(item) {
            this.videourl = item
            this.showvideoplay = true
        }
    },
}